import React from 'react'
import {Link} from 'react-router-dom'
import {Menu} from 'semantic-ui-react'
import header from 'images/page-header.png'

const NavBar = () => {
    return (
        <div className="page-header">
            <div className="links">
                <Link className="home" exact="true" to="/">Yakilla</Link>
                <div className="search">
                    <Link to="/entries">Search</Link>
                </div>
            </div>
        </div>
    )
}

export default NavBar
