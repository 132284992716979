import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { connect, useSelector } from "react-redux"
import { useFirestoreConnect, isLoaded } from "react-redux-firebase"
import _ from 'lodash'
import { Container, Button, Dropdown, Header, Segment, Image, Input } from 'semantic-ui-react'
import NavBar from "Components/NavBar"
import EntryContent from "Components/EntryContent"
import { skills, qualifications } from "./categories"
import { getName } from "Components/helpers"

const EntryList = props => {

  const skillsOptions = skills.map((skill, i) => (
    { "key": i, "text": skill.name, "value": skill.id }
  ))

  const qualificationsOptions = qualifications.map((qualification, i) => (
    { "key": i, "text": qualification.name, "value": qualification.id }
  ))

  // Provider Options come from Firetore

  const [searchTerm, setSearchTerm] = useState("")
  const [sortBy, setSortBy] = useState("name")
  const [sortOrder, setSortOrder] = useState(">")
  const [entries, setEntries] = useState([])
  const [skillsFilter, setSkillsFilter] = useState([])
  const [qualificationsFilter, setQualificationsFilter] = useState([])
  const [providersFilter, setProvidersFilter] = useState([])
  const delayedQuery = useCallback(_.debounce(term => setSearchTerm(term), 500), []);

  let option = [{ collection: "entries" }, { collection: "providers" }]
  useFirestoreConnect(option)
  const rawEntries = useSelector(state => state.firestore.ordered.entries)
  const providers = useSelector(state => state.firestore.ordered.providers)

  let providersOptions = null
  if (providers && providers !== "undefined") {
    providersOptions = providers.map((provider, i) => {
      return {"key": i, "text": provider.name, "value": provider.id}
    })
    providersOptions.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
  }


  // Filter and sort helpers
  // Strip punctuation and spaces and convert to lowercase for better string comparison for sorting
  const sort_prep = (str) => {
    return str.replace(/[^\w\s]|_/g, "").replace(/\s+/g, "").toLowerCase();
  }
  // Variable comparison operator technique from https://stackoverflow.com/a/10591359
  const name_operator_table = {
    '>': (a, b) => (sort_prep(a.name) > sort_prep(b.name)),
    '<': (a, b) => (sort_prep(a.name) < sort_prep(b.name))
  }

  const addItemToFiltered = (filtered, item, skillMatch, qualificationMatch, providerMatch) => {
    return [...filtered, {
      ...item,
      "skillMatch": skillMatch,
      "qualificationMatch": qualificationMatch,
      "providerMatch": providerMatch
    }]
  }

  const filterEntries = data => {
    let filtered = []

    const usingFilters = skillsFilter.length > 0 || qualificationsFilter.length > 0 ? true : false

    if (data) {
      data.map(item => {
        const nameMatch = item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase()) ? true : false
        const textMatch = item.info && item.info.toLowerCase().includes(searchTerm.toLowerCase()) ? true : false
        const skillMatch = item.skills && item.skills.filter(
            skill => skillsFilter.includes(skill)).length > 0 ? true : false
        const qualificationMatch = item.qualifications && item.qualifications.filter(
            qualification => qualificationsFilter.includes(qualification)).length > 0 ? true : false

        const providerMatch = providersFilter.includes(item.provider)

        // Use this for additive filtering (eg show results that match either filter)
        // if (usingFilters && (!(skillMatch || qualificationMatch))) {
        //   return
        // }

        // Use this for reductive filtering (eg, only show results that match all filters)
        if (skillsFilter.length > 0 && !skillMatch) {
          return
        }
        if (qualificationsFilter.length > 0 && !qualificationMatch) {
          return
        }
        if (providersFilter.length > 0 && !providerMatch) {
          return
        }

        // Filter items by search matches
        if (nameMatch || textMatch) {
          filtered = addItemToFiltered(filtered, item, skillMatch, qualificationMatch, providerMatch)
        }
      })
      // Sort
      if (sortBy === "name") filtered.sort((a, b) => name_operator_table[sortOrder](a, b) ? 1 : -1)
    }
    return filtered
  }

  useEffect(() => {
    if (rawEntries === entries) return
    setEntries(filterEntries(rawEntries))
  }, [rawEntries, searchTerm, sortBy, sortOrder, skillsFilter, qualificationsFilter, providersFilter])

  const changeSearchTerm = e => {
    // TODO sanitise etc
    // setSearchTerm(e.target.value)
    // debounce version from https://medium.com/@rajeshnaroth/using-throttle-and-debounce-in-a-react-function-component-5489fc3461b3
    delayedQuery(e.target.value)
  }

  const changeSortBy = () => {
    // const newSortBy = (sortBy === "date") ? "name" : "date"
    setSortBy("name")
  }

  const changeSortOrder = () => {
    const newSortOrder = (sortOrder === "<") ? ">" : "<"
    setSortOrder(newSortOrder)
  }

  const handleSkillsChange = (e, { value }) => setSkillsFilter(value)

  const handleQualificationsChange = (e, { value }) => setQualificationsFilter(value)
  
  const handleProvidersChange = (e, { value }) => setProvidersFilter(value)

  return (
    <Container className="entry-list-page">
      <NavBar />
      <div className="list-tools">
        <div className="list-tools-fake-bgd" />

        <div className="search">
          <Input
            icon='search'
            placeholder='Search...'
            onChange={changeSearchTerm}
          />

          <Dropdown
            placeholder='Knowledge'
            multiple
            search
            selection
            options={skillsOptions}
            onChange={handleSkillsChange}
          />

          <Dropdown
            placeholder='Qualifications'
            multiple
            search
            selection
            options={qualificationsOptions}
            onChange={handleQualificationsChange}
          />
          
          <Dropdown
            placeholder='Provider'
            multiple
            search
            selection
            options={providersOptions}
            onChange={handleProvidersChange}
          />
        </div>

        <div className="sort">
          {/* 
            <Button basic onClick={changeSortBy}>
              Sort by
              {sortBy === "date" ? " name" : " date"}
            </Button>
             */}
          <Button
            basic
            circular
            inverted
            icon={sortOrder === "<" ?
              "sort alphabet up" :
              "sort alphabet down"}
            onClick={changeSortOrder} />
        </div>

      </div>
      <Container >
        {entries && isLoaded(rawEntries) && entries.length===0 &&
          <p>Nothing found, please try another search.</p>
        }
        
        {entries && providers && entries.map((data, i) => (
          <div key={data.id} className="entry">

            <Header as="h2" className="content-header">
              <Link to={`/entry/${encodeURI(data.name)}`}>{data.name}</Link>
            </Header>
            {data.provider &&
            <Header as="h3" className="provider">
              {getName(providers, data.provider)}
            </Header>
            }

            {data.skills && data.skills.length > 0 &&
              <div className="content tags">
                <label className="tags-label">Knowledge:</label>
                <ul className="tags">
                  {data.skills.map(skill => (
                    <li key={skill}>{getName(skills, skill)}</li>
                  ))}
                </ul>
              </div>
            }

            {data.qualifications && data.qualifications.length > 0 &&
              <div className="content tags">
                <label className="tags-label">Qualifications:</label>
                <ul className="tags">
                  {data.qualifications.map(qualification => (
                    <li key={qualification}>{getName(qualifications, qualification)}</li>
                  ))}
                </ul>
              </div>
            }

          </div>
          // <EntryContent
          //   key={i}
          //   data={data}
          //   autoExcerpt={true}
          // />
        ))}
      </Container>
    </Container>
  )
}

// TODO use redux for the search and filter, 
// and then could move the search and filter bits out to their own components. 
function mapStateToProps(state) {
  const { test } = state.yakilla
  return { test: test }
}

export default connect(mapStateToProps)(EntryList)
