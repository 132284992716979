import {combineReducers} from "redux"
import {firebaseReducer} from "react-redux-firebase"
import {firestoreReducer} from "redux-firestore"


const initialState = {
    test: 'bbb',
    searchTerm: '',
    searchStatus: 'ready'
}
const yakillaReducer = (state=initialState, action) => {
    switch (action.type) {
        case 'SET_SEARCH_TERM':
            return { ...state, searchTerm: action.term }
        default:
            return state
    }
}

export const rootReducer = combineReducers({
    yakilla: yakillaReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer
})
