import React from "react"
import { Link } from "react-router-dom"
import { Container, Header, Segment, Icon, Image } from 'semantic-ui-react'
import { RawHTML } from "Components/RawHTML"
import LazyLoad from 'react-lazyload';
import { format } from 'date-fns'
import { skills, qualifications } from "./categories"
import trunc from "trunc-html"
import CourseDetails from "Components/CourseDetails"
import { getName } from "Components/helpers"

const EntryContent = props => {
  const { data, autoExcerpt } = props

  const trunc_limit = 300

  if (data) {
    console.log(data)
    let data_info = autoExcerpt ? trunc(data.info, trunc_limit).html : data.info

    let matched = data.skillMatch || data.qualificationMatch ? ["matched"] : []
    if (data.skillMatch) matched.push("skill")
    if (data.qualificationMatch) matched.push("qualification")
    matched = matched.join(" ")

    return (
      <LazyLoad height={200} offset={100}>
        <Segment className={`card fluid ${matched}`}>

          <Header as="h2" className="content-header">
            <Header as={Link} to={`/entry/${encodeURI(data.name)}`}>{data.name}</Header>
          </Header>

          {/* auto-excerpt using trunc-html  */}
          <div className="content dont-break-out">
            <RawHTML>{data_info}</RawHTML>
          </div>

          {data.skills && data.skills.length > 0 &&
            <div className="content dont-break-out">
              <h3>Knowledge</h3>
              {data.skills.map(skill => (
                <li key={skill}>{getName(skills, skill)}</li>
              ))}
            </div>
          }

          {data.qualifications && data.qualifications.length > 0 &&
            <div className="content dont-break-out">
              <h3>Qualifications</h3>
              {data.qualifications.map(qualification => (
                <li key={qualification}>{getName(qualifications, qualification)}</li>
              ))}
            </div>
          }

          {data.links && data.links.length > 0 &&
            <div className="content dont-break-out">
              {/* <h3>Links</h3> */}
              <ul className="links">
                {data.links.map(link => (
                  <li key={link.url}>
                    <a href={link.url} title={link.title} target="_blank">
                      <Icon name="angle right" color='green' />
                       &nbsp;
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          }

          {data.courses && data.courses.length > 0 &&
            <div className="content dont-break-out">
              <h3>Courses</h3>
              {data.courses.map(course => (
                <CourseDetails data={course} key={course.name} autoExcerpt={autoExcerpt} />
              ))}
            </div>
          }

          {data.document &&
            <div className="content dont-break-out">
               <a href={data.document.src} title={data.documenttitle ? data.documenttitle : data.document.title} target="_blank" rel="noopener noreferrer">
                  {data.documenttitle ? data.documenttitle : data.document.title}
                </a>
            </div>
          }
        </Segment>
      </LazyLoad>
    )
  } else {
    return <p> loading </p>
  }
}

export default EntryContent
