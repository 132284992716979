import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { connect, useSelector } from "react-redux"
import { useFirestoreConnect } from "react-redux-firebase"
import _ from 'lodash'
import { Container, Grid } from 'semantic-ui-react'
import NavBar from "Components/NavBar"
import { skills, qualifications } from "./categories"
import { getName } from "Components/helpers"

const EntryGrid = props => {

  let option = { collection: "entries" }
  useFirestoreConnect(option)
  const entries = useSelector(state => state.firestore.ordered.entries)
  const colours = [
    'red',
    'orange',
    'yellow',
    'olive',
    'green',
    'teal',
    'blue',
    'violet',
    'purple',
    'pink',
    'brown',
    'grey',
    'black',
  ]
  // counter to step through the colour list
  let j = -1
  return (
      <Grid columns={4} stackable padded className="entry-grid">
        {entries && entries.map((data, i) => {
          j = j < 10 ? ++j : 0
          return (
            <Grid.Column key={data.id} color={colours[j]} as={Link} to={`/entry/${encodeURI(data.name)}`}>
              <p>{data.name}</p>
              {data.skills &&
                <div className="skills">
                  {data.skills.map(skill => (
                    <p key={skill}>{getName(skills, skill)}</p>
                  ))}
                </div>
              }
            </Grid.Column>
          )
        }
        )}
      </Grid>
  )
}

export default EntryGrid
