import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from "react-router-dom"

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import { applyMiddleware, compose, createStore } from "redux"
import thunk from 'redux-thunk';
import { Provider } from "react-redux"
import { getFirebase, ReactReduxFirebaseProvider } from "react-redux-firebase"
import { createFirestoreInstance } from "redux-firestore"
import { rootReducer } from "Store/reducers"

import 'index.css'
import 'theme.css'
import 'semantic-ui-css/semantic.min.css'

import App from './App'
import ReactGA from "react-ga4";



// Get Firebase config from env
const config = {
  apiKey: "AIzaSyBcad0kiau_mqpc8v8REvxGOaKV6qh6sVc",
  authDomain: "yakilla.firebaseapp.com",
  databaseURL: "https://yakilla.firebaseio.com",
  projectId: "yakilla",
  storageBucket: "yakilla.appspot.com",
  messagingSenderId: "662208085864",
  appId: "1:662208085864:web:c259b5e0af58da98eca0dd",
  measurementId: "G-2CGZ8Z8F1H"
}
const rrfConfig = {
}

firebase.initializeApp(config)
firebase.firestore()

const initialState = {}

// const middlewares = [
//   thunk.withExtraArgument(getFirebase)
// ]

const store = createStore(
  rootReducer,
  initialState,
  // compose(
  //   applyMiddleware(...middlewares)
  // )
)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // since we are using Firestore
}

ReactGA.initialize("G-2CGZ8Z8F1H");

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
)
