import React from "react"
import {NavLink} from 'react-router-dom'
import {Container, Grid, Image, Segment, List, ListItem} from 'semantic-ui-react'
import {useSelector} from "react-redux"
import {Link} from 'react-router-dom'
import {useFirestoreConnect, isLoaded} from "react-redux-firebase"
import hash from "object-hash"
import EntryGrid from "Components/EntryGrid"
import {RawHTML} from "Components/RawHTML"
import header from 'images/home-page-header.png'
import artwork from 'images/doreenchapman_ls.jpg'

const Home = () => {
    //  Load the home page text from Firestore
    let option = {collection: "pages"}
    useFirestoreConnect(option)
    const pages = useSelector(state => state.firestore.ordered.pages)
    console.log("pages", pages)
    // let home_page_content = isLoaded(pages) ? pages.filter(page => page.name==="home") : null

    let home_content= null
    let credit_content = null
    if (isLoaded(pages)) {
        let pages_filter = pages.filter(page => page.name==="home")
        home_content = pages_filter[0]
        pages_filter = pages.filter(page => page.name==="credits")
        credit_content = pages_filter[0]
    }
    return (
        <div className="home-page">
            <Image className="logo" src={header} alt="Yakilla: Training tracks site header image" />

            <Grid columns={2} stackable padded className="splash">

                <Grid.Column className="home-page-search">
                    <Link to={"entries"}>Search</Link>
                </Grid.Column>

                <Grid.Column className="home-page-content">
                    {home_content && home_content.info &&
                    <div>
                        <RawHTML>{home_content.info}</RawHTML>
                    </div>
                    }
                </Grid.Column>
            </Grid>

            <EntryGrid/>

            {credit_content && credit_content.info &&
            <Container className="credits">
                <Image className="creditsImage" src={artwork} alt="Doreen Chapman" />
                <div> <RawHTML>{credit_content.info}</RawHTML>  </div>
            </Container>
            }

        </div>
    )
}

export default Home
