import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from 'react-router-dom'
import { useFirestoreConnect, isLoaded } from "react-redux-firebase"
import { Container, Segment, Image } from 'semantic-ui-react'
import NavBar from "Components/NavBar"
import EntryContent from "Components/EntryContent"
import hash from "object-hash"

const Entry = () => {
  let { name } = useParams()
  let name_hash = hash(name)
  let option = { collection: "entries", where: ["name", "==", name], storeAs: name_hash }
  useFirestoreConnect(option)
  const entries = useSelector(state => state.firestore.ordered[name_hash])
  let data = isLoaded(entries) ? entries[0] : null

  return (
    <Container className="entry-page">
      <NavBar />
      <Container>
        <EntryContent data={data} autoExcerpt={false} />
      </Container>
    </Container>
  )
}

export default Entry
