// Remember to update the admin site if this changes

export const skills = [
  { id: "applied-linguistics", name: "Applied linguistics" },
  { id: "collection-management", name: "Collection management" },
  { id: "documentation", name: "Documentation" },
  { id: "interpreting", name: "Interpreting" },
  { id: "language-learning", name: "Language learning" },
  { id: "language-research", name: "Language research" },
  { id: "legal-and-governance", name: "Law and governance" },
  { id: "linguistics", name: "Linguistics" },
  { id: "literacy-and-numeracy", name: "Literacy and numeracy" },
  { id: "media", name: "Media" },
  { id: "project-management", name: "Project management" },
  { id: "resource-production", name: "Resource production" },
  { id: "revival-techniques", name: "Revival techniques" },
  { id: "teaching", name: "Teaching" },
  { id: "translating", name: "Translating" }
]

export const qualifications = [
  { id: "association", name: "Association" },
  { id: "cadetship", name: "Cadetship" },
  { id: "degree", name: "Degree" },
  { id: "fellowship", name: "Fellowship" },
  { id: "further-degree", name: "Further Degree" },
  { id: "illustrations-of-practice", name: "Illustrations of practice" },
  { id: "internship", name: "Internship" },
  { id: "traineeship", name: "Traineeship" },
  { id: "vocational-education", name: "Vocational education" },
  { id: "workshop", name: "Workshop" },
]
