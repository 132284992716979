import React from "react"
import { Container, Header, Segment, Icon, Image } from 'semantic-ui-react'
import { RawHTML } from "Components/RawHTML"
import { skills, qualifications } from "./categories"
import trunc from "trunc-html"
import { getName } from "Components/helpers"

const CourseDetails = props => {
  const { data, autoExcerpt } = props

  const trunc_limit = 200

  let data_info = autoExcerpt ? trunc(data.info, trunc_limit).html : data.info

  return (
    <>
      {
        data.name &&
        <h3>{data.name}</h3>
      }
      {data_info &&
        <div className="content dont-break-out">
          <RawHTML>{data_info}</RawHTML>
        </div>
      }

      {data.links && data.links.length > 0 &&
        <div className="content dont-break-out">
          {/* <h3>Links</h3> */}
          <ul className="links">
            {data.links.map(link => (
              <li key={link.url}>
                <a href={link.url} title={link.title} target="_blank">
                  <Icon name="angle right" color='green' />
                       &nbsp;
                      {link.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      }

      {data.skills && data.skills.length > 0 &&
        <div className="content dont-break-out">
          <h3>Knowledge</h3>
          <ul className="skills">
            {data.skills.map(skill => (
              <li key={skill}>{getName(skills, skill)}</li>
            ))}
          </ul>
        </div>
      }

      {data.qualifications && data.qualifications.length > 0 &&
        <div className="content dont-break-out">
          <h3>Qualifications</h3>
          <ul className="qualifications">
            {data.qualifications.map(qualification => (
              <li key={qualification}>{getName(qualifications, qualification)}</li>
            ))}
          </ul>
        </div>
      }
    </>
  )
}

export default CourseDetails
